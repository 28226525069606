import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import { HashLink as Link } from 'react-router-hash-link';

import logo from '../images/logo-light-blue.svg';
import logo_white from '../images/logo-white-seethrough.svg';

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      headerStyle: null
    };

    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll, { passive: true })
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll(event) {
    // console.log(window.location.pathname, event, window.scrollY, window.innerHeight, this.props.className);
    if (this.props.className === "transparent") {
      this.setState({
        headerStyle: { top: window.scrollY }
      });
    }
  }

  render() {
    return (
      <header className={this.props.className} style={this.state.headerStyle}>
        <div className="container">
          <Link to="/"><img src={(this.props.className === "solid") ? logo : logo_white} className="logo" alt="Build Software Partners" /></Link>
          <nav>
            <ul>
              <li>
                <NavLink exact to="/"><span>Our Approach</span></NavLink>
              </li>
              <li>
                <NavLink to="/team"><span>Our Team</span></NavLink>
              </li>
              <li>
                <Link smooth to="#contact-us">Contact Us</Link>
              </li>
            </ul>
          </nav>
        </div>
      </header>
    );
  }
}

export default Header;
