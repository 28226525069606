import React, { Component } from 'react';

class ContactUs extends Component {
  constructor(props) {
    super(props);
  	this.state = {
      success: null,
    	name: '',
    	email: '',
      company: '',
      phone: '',
    	message: '',
      errors: [],
      sending: false
  	}

    this.onChange = this.onChange.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }

  onChange(e) {
    let name = e.target.name;
    let value = e.target.value;

    this.setState({
      [name]: value
    });
  }

  resetForm() {
    this.setState({
      name: '',
      email: '',
      company: '',
      phone: '',
      message: '',
    });
  }

  submitForm(e) {
    e.preventDefault();
    let errors = [];

    if (!this.state.sending) {
      this.setState({
        errors: errors,
        sending: true,
      }, () => {
        fetch('http://localhost.andrewu.net/bsp/public/send.php', {
          method: "POST",
          body: JSON.stringify(this.state),
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
        }).then((response) => (
          response.json()
        )).then((response) => {
          if (response.status === 'success') {
            this.setState({
              success: true,
              sending: false,
            }, () => this.resetForm())
          } else if(response.status === 'fail') {
            response.error.forEach((item, i) => {
              errors.push(<p key={i}>{item}</p>)
            });
            this.setState({
              errors: errors,
              sending: false,
            });
          }
        });
      });
    }
  }

  render() {
    const { success, name, email, company, phone, message, errors } = this.state;

    return (
      <div id="contact-us">
        <div className="leftContainer">
          <div className="leftBorder"></div>
        </div>
        <h3>Contact us</h3>
        {success ?
          <div className="success">
            Thank you for your message.
          </div>
          :
          <form id="contact-form" onSubmit={this.submitForm} method="POST">
            <div className="row">
              <input type="text" name="name" className="form-control" value={name} onChange={(e) => this.onChange(e)} placeholder="Your Name" />
              <div className="spacer"></div>
              <input type="text" name="company" className="form-control" value={company} onChange={(e) => this.onChange(e)} placeholder="Company Name" />
            </div>
            <div className="row">
              <input type="email" name="email" className="form-control" value={email} onChange={(e) => this.onChange(e)} placeholder="Email Address" />
              <div className="spacer"></div>
              <input type="tel" name="phone" className="form-control" value={phone} onChange={(e) => this.onChange(e)} placeholder="Phone Number" />
            </div>
            <textarea className="form-control" name="message" rows="5" value={message} onChange={(e) => this.onChange(e)} placeholder="Your Message" />
            {errors &&
              <div className="errors">
                {errors}
              </div>
            }
            <button type="submit" className="btn btn-primary">Submit</button>
            <div className="clear"></div>
          </form>
        }
        <div className="rightBorder"></div>
      </div>
    );
  }
}

export default ContactUs;
