import React, { Component } from 'react';

import ContactUs from './ContactUs.js';

class Footer extends Component {
  // constructor(props) {
  //   super(props);
  // }

  render() {
    return (
      <footer>
        <div className="container">
          <ContactUs />
          <div className="inner_container">
            <div className="copyright">
              <div className="leftBorder"></div>
              <div>
                <strong>© Build Software Partners</strong> - All Rights Reserved<br />
                23 Hollywood Avenue<br />
                Toronto, Ontario<br />
                Canada M2N 7L8
              </div>
            </div>
          </div>
          <div className="clear"></div>
        </div>
      </footer>
    );
  }
}

export default Footer;
