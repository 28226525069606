import React, { Component } from 'react';
import { HashLink as Link } from 'react-router-hash-link';

import Header from '../Components/Header.js'

import hero_video from '../images/16132036-preview.mp4';
import hero_video2 from '../images/26264675-preview.mp4';

class Hero extends Component {
  constructor(props) {
    super(props);

    this.state = {
    }

    this.word0 = React.createRef();
    this.word1 = React.createRef();
    this.word2 = React.createRef();

    this.startTimer = this.startTimer.bind(this);
  }

  startTimer() {
    var captionIdx = 0;
    this.timerId = setInterval(()=>{
      // let element = this["word"+captionIdx];
      this.word0.current.style.opacity = (captionIdx === 0) ? 1 : 0;
      this.word1.current.style.opacity = (captionIdx === 1) ? 1 : 0;
      this.word2.current.style.opacity = (captionIdx === 2) ? 1 : 0;
      captionIdx = (captionIdx === 2) ? 0 : captionIdx+1;
    }, 2500);
  }

  componentDidMount() {
    this.startTimer();
  }

  componentWillUnmount() {
    clearInterval(this.timerId)
  }

  render() {
    return (
      <div className="Hero">
        <Header className="transparent" />
        <div className="container">
          <h1>We are software&nbsp;
            <div id="carousel" className="yellow">
              <span ref={this.word0}>entrepreneurs</span>
              <span ref={this.word1}>investors</span>
              <span ref={this.word2}>operators</span>
            </div>
            &nbsp;building companies into <span>market leaders</span>
          </h1>
          <Link smooth to="#contact-us"><button>Contact Us</button></Link>
        </div>
        <video autoPlay={true} loop={true} muted>
          <source src={Math.round(Math.random()) ? hero_video : hero_video2} type="video/mp4" />
        </video>
      </div>
    );
  }
}

export default Hero;
