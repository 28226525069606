import React, { Component } from "react";
import { HashRouter as Router, Switch, Route } from "react-router-dom";

import './App.scss';

import Header from './Components/Header.js'
import ScrollToTop from './Components/ScrollToTop.js';
import Approach from './Components/Approach.js'
import Team from './Components/Team.js'
import Footer from './Components/Footer.js';

class App extends Component {
  render() {
    return (
      <Router>
		    <ScrollToTop />
        <div className="bsp">
          <Header className="solid" />
          <Switch>
            <Route exact path="/">
              <Approach />
            </Route>
            <Route path="/team">
              <Team />
            </Route>
          </Switch>
          <Footer />
        </div>
      </Router>
    );
  }
}

export default App;
