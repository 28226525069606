import React, { Component } from 'react';

import Hero from './Hero.js';

import seller_one from '../images/jim_dimico@2x.png';
import seller_two from '../images/dean_schreiner@2x.png';

class Approach extends Component {
  // constructor(props) {
  //   super(props);
  // }

  render() {
    return (
      <div className="Approach">
        <Hero />
        <div className="different">
          <div className="container">
            <div className="leftBorder"></div>
            <div className="inner_container">
              <h2>What makes us different<div className="line"></div></h2>
              <ul>
                <li><strong>We are software entrepreneurs first</strong>, and investors second</li>
                <li>We provide <strong>clean, simple, and no-BS deal structures</strong></li>
                <li>We don’t just provide capital, <strong>we get in the trenches</strong></li>
              </ul>
            </div>
            <div className="rightBorder"></div>
          </div>
        </div>
        <div className="sellers">
          <div className="container">
            <h2>What Sellers Say<div className="line"></div></h2>
          </div>
          <div className="seller_one">
            <img src={seller_one} alt="Jim DiMico" />
            <div className="testimonial">
              “Steve was a straight shooter throughout my entire sales process. There were no games or re-trading. With him, I knew that my customers, employees and legacy would be in great hands.”
              <div className="from">
                <div className="name">Jim DiMico</div>
                <div className="title">Former Owner of CoolFront Technologies</div>
              </div>
              <div className="rightContainer">
                <div className="rightBorder"></div>
              </div>
            </div>
          </div>
          <div className="seller_two">
            <div className="testimonial">
              <div className="leftContainer">
                <div className="leftBorder"></div>
              </div>
              “Rameez and Steve have done a fantastic job taking care of my company and employees. It’s always a pleasure to visit the office to see some old faces, and many, MANY new ones.”
              <div className="from">
                <div className="name">Dean Schreiner</div>
                <div className="title">Former Owner of FieldEdge (dESCO, LLC)</div>
              </div>
            </div>
            <img src={seller_two} alt="Dean Schreiner" />
          </div>
          <div className="clear"></div>
        </div>
        <div className="look">
          <div className="container">
            <div className="leftBorder"></div>
            <div className="inner_container">
              <h2>What we look for<div className="line"></div></h2>
              <ul>
                <li><strong>Software Businesses</strong> in <strong>Growing Markets</strong></li>
                <li><strong>Cash Margins</strong> above <strong>10%</strong></li>
                <li><strong>Annual recurring revenue between $2M and $30M</strong></li>
                <li><strong>Located</strong> in <strong>North America</strong></li>
              </ul>
            </div>
            <div className="rightBorder"></div>
          </div>
        </div>
      </div>
    );
  }
}

export default Approach;
