import React, { Component } from 'react';

import leader_1 from '../images/rameez@2x.png';
import leader_2 from '../images/steve@2x.png';
import leader_3 from '../images/kyle@2x.png';
import leader_1_bio from '../images/rameez_bio@2x.png';
import leader_2_bio from '../images/steve_bio@2x.png';
import leader_3_bio from '../images/kyle_bio@2x.png';

import advisor_1 from '../images/dave@2x.png';
import advisor_2 from '../images/sekhar@2x.png';
import advisor_3 from '../images/khurram@2x.png';

import award_1 from '../images/award_1@2x.png';
import award_2 from '../images/award_2@2x.png';
import award_3 from '../images/award_3@2x.png';
import award_4 from '../images/award_4@2x.png';
import award_5 from '../images/award_5@2x.png';
import award_6 from '../images/award_6@2x.png';
import award_7 from '../images/award_7@2x.png';

import close from '../images/times.svg';

class Team extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open_bio: false,
      active_leader: null,
      bioStyle: null
    };

    this.bioRef = React.createRef();
    this.selectLeader = this.selectLeader.bind(this);
    this.closeBios = this.closeBios.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.escFunction = this.escFunction.bind(this);
  }

  escFunction(event){
    if(event.keyCode === 27) {
      this.closeBios();
    }
  }

  componentDidMount() {
    document.addEventListener("keydown", this.escFunction, false);
    window.addEventListener('resize', this.handleResize, { passive: true })
    this.handleResize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
    document.removeEventListener("keydown", this.escFunction, false);
  }

  handleResize(event) {
    if (window.innerHeight <= 450 && !this.state.bioStyle) {
      this.setState({
        bioStyle: { transform: 'none', top: 0 }
      })
    } else if (window.innerHeight > 450 && this.state.bioStyle) {
      this.setState({
        bioStyle: null
      })
    }
  }

  closeBios() {
    this.setState({
      open_bio: false
    })
  }

  selectLeader(e, name) {
    const { open_bio, active_leader } = this.state;
    e.preventDefault();

    if (!open_bio) {
      this.setState({
        open_bio: true,
        active_leader: name
      });
    } else if (open_bio && active_leader === name) {
      this.setState({
        open_bio: false
      });
    } else if (open_bio && active_leader !== name) {
      this.setState({
        open_bio: false
      }, () => {
        setTimeout(() => {
          this.setState({
            open_bio: true,
            active_leader: name,
          })
        }, 500);
      });
    }
  }

  render() {
    const {open_bio, active_leader} = this.state;

    let bio_background = null;
    let bio_text = "";

    if (active_leader) {
      switch (active_leader) {
        case "rameez":
          bio_background = { backgroundImage: `url(${leader_1_bio})` };
          bio_text = (<>
            <p>Rameez is Managing Partner and has been in operations and investing for over 20 years. Previously, Rameez was co-CEO of FieldEdge, a service management software provider for the home contractor market that he acquired in 2015. In three years, Rameez scaled the sales and marketing team from 3 to 75 employees and led the company's transition from server-based to cloud-based software. Rameez also spent time as a consultant at BCG and grew up in his family business, a small power generator assembly company.</p>
            <p>In 2018, Rameez was a recipient of the EY Entrepreneur of the Year award. Rameez received a Bachelor of Business Administration degree from the University of Toronto where he currently an Adjunct Professor in the management program. He is also a graduate of the Stanford School of Business' Master of Business Administration (MBA) program.</p>
            <p><strong>Unforgettable operator moment:</strong> Nervously watching my first product launch in the hands of customers</p>
            <p><strong>Nickname in sports:</strong> Rampage. What I lack in athletic ability, I make up for in fierce determination.</p>
            <p><strong>What gets me up in the morning:</strong> Coaching and influencing others</p>
            <p><strong>Interesting fact:</strong> During the 2018 Masters, one of Freddie Couple's shots hit me in the head. If you find the video online, make sure you turn the volume on.</p>
          </>);
          break;
        case "steve":
          bio_background = { backgroundImage: `url(${leader_2_bio})` };
          bio_text = (<>
            <p>Steve is Managing Partner and has been in entrepreneurship and investing for over 15 years.  Previously, Steve was co-CEO of FieldEdge, where he scaled the company from 25 to 200 employees in three years and successfully exited to Advent International. Steve has also spent time in investing roles at Whitecap Venture Partners and CPP Investment Board.</p>
            <p>In 2018, Steve was a recipient of the EY Entrepreneur of the Year award, and in 2019, he was recognized as one of Canada's Top 40 Under 40. He holds a Bachelor of Business Administration degree from the University of Toronto where he is currently an Adjunct Lecturer for the Management program. He is also a graduate of the Wharton Business School’s Master of Business Administration (MBA) program.</p>
            <p><strong>Unforgettable operator moment:</strong> Watching hurricane Irma go directly over my company's headquarters and half the employees' homes.</p>
            <p><strong>Nickname in sports:</strong> The cockroach. I'm that guy whose still punting in 110% even when he's losing match point to zero.</p>
            <p><strong>What gets me up in the morning:</strong> My wonderful children... although a little less wonderful at 6am.</p>
            <p><strong>Interesting fact:</strong> Visited 30 countries before turning 30 and while travelling, participated in the worlds largest tomato fight, the running of the bulls, and camped with nomads in the Sahara Dessert</p>
            </>);
          break;
        case "kyle":
          bio_background = { backgroundImage: `url(${leader_3_bio})` };
          bio_text = (<>
            <p>Kyle has wide-ranging experience as an operating executive at fast-growing SaaS businesses. Kyle most recently led Finance and Operations at HiMama, a vertical SaaS business in the daycares market, and previously he built up the Customer Success function at FieldEdge from 0 to 25 employees. Earlier, Kyle worked in investment banking at BMO Capital Markets, and studied business administration at Ivey Business School.</p>
            <p>Kyle loves to travel and practice amateur photography with his fiance, and he always makes sure to add local bouldering gyms to the itinerary.</p>
          </>);
          break;
        default:
          break;
      }
    }

    return (
      <div className="Team">
        <div className="People">
          <div className="container">
            <h2>Leadership Team<div className="line"></div></h2>
            <div className={`inner_container `+(active_leader && open_bio && "active")}>
              <button className={`person `+(active_leader === "rameez" && open_bio && "active")} onClick={(e) => this.selectLeader(e, "rameez")}>
                <div className="image_wrapper">
                  <img src={leader_1} alt="" />
                </div>
                <div className="who">
                  <div className="name">Rameez Ansari</div>
                  <div className="title">Managing Partner</div>
                </div>
              </button>
              <button className={`person `+(active_leader === "steve" && open_bio && "active")} onClick={(e) => this.selectLeader(e, "steve")}>
                <div className="image_wrapper">
                  <img src={leader_2} alt="" />
                </div>
                <div className="who">
                  <div className="name">Steve Lau</div>
                  <div className="title">Managing Partner</div>
                </div>
              </button>
              <button className={`person `+(active_leader === "kyle" && open_bio && "active")} onClick={(e) => this.selectLeader(e, "kyle")}>
                <div className="image_wrapper">
                  <img src={leader_3} alt="" />
                </div>
                <div className="who">
                  <div className="name">Kyle Zhao</div>
                  <div className="title">Vice President</div>
                </div>
              </button>
            </div>
          </div>

          <div className={`bio ${(open_bio) ? "active" : "inactive"}`} ref={this.bioRef}>
            <button className="underlay" onClick={this.closeBios}></button>
            <div className="bio_container" style={this.state.bioStyle}>
              <button className="close" onClick={this.closeBios}><img src={close} alt="Close biography" /></button>
              <div className="container">
                <div className="image_wrapper" style={bio_background}>
                  <div className="leftBorder"></div>
                </div>
                <div className="text_wrapper">
                  <div className="leftBorder"></div>
                  <div className="text">{bio_text}</div>
                  <div className="rightBorder"></div>
                </div>
                <div className="clear"></div>
              </div>
            </div>
          </div>

          <div className="container marginTop88">
            <h2>Advisors<div className="line"></div></h2>
            <div className="inner_container">
              <div className="person">
                <img src={advisor_1} alt="" />
                <div className="who">
                  <div className="name">Dave Gregory</div>
                  <div className="title">Finance & Strategy Advisor</div>
                  <div className="subtitle">Managing Director, Brookfield Private Equity</div>
                </div>
              </div>
              <div className="person">
                <img src={advisor_2} alt="" />
                <div className="who">
                  <div className="name">Sekhar Makkapati</div>
                  <div className="title">Technology Advisor</div>
                  <div className="subtitle">Former VP Engineering, FieldEdge</div>
                </div>
              </div>
              <div className="person">
                <img src={advisor_3} alt="" />
                <div className="who">
                  <div className="name">Khurram Zia</div>
                  <div className="title">Product & Strategy Advisor</div>
                  <div className="subtitle">Product, Dropbox, Amazon, Microsoft</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="Awards">
          <div className="container">
            <h2>We build award-winning companies<div className="line"></div></h2>
            <div className="inner_container">
              <img src={award_1} className="award" alt="EY Entrepreneur Of The Year®" />
              <img src={award_2} className="award" alt="glassdoor® 2018 Best Places to Work, Employees' Choice" />
              <img src={award_3} className="award" alt="2018 Stevie® Silver Winner for Sales & Customer Service" />
              <img src={award_4} className="award" alt="People's Choice Stevie® Awards for Favorite Customer Service, 2018 Winner" />
              <img src={award_5} className="award" alt="Inc. 500" />
              <img src={award_6} className="award" alt="2019 Canada's Top Growing Companies, Report on Business" />
              <img src={award_7} className="award" alt="Gartner® 2017, FrontRunners, Leader, Software Advice TM" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Team;
